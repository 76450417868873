import React from "react"
import Image from "../utils/use-gatsby-local-image.js"
import Head from "../components/head"
import Layout from "../components/layout"
import { GroupSignUp } from "../components/groups/sign-up"
import { GroupPlan } from "../components/groups/group-plan"

const Groups = () => {
    return (
        <Layout activePage="Groups">
            <Head title={"Groups"} />
            <div className="container mb-20 flex items-center">
                <div className="w-full md:w-1/2 flex flex-col my-20">
                    <h2 className="mb-6 text-pink-500">Digital Sabbath plan for small groups </h2>
                    <p className="w-2/3">Run a Digital Sabbath in your small group, follow the weekly plan below and fast one day a week for 1 month in community. </p>
                </div>
                <div className="hidden md:block md:w-1/2 xl:w-1/3 md:absolute md:right-0 xl:right-40">
                    <Image
                        fileName="rest.png"
                        className="max-h-92"
                        alt="A cup of coffee and a Bible on a wooden railing by a sunny lake" />
                </div>
            </div>
            <div className="mt-10">
            <GroupSignUp
                title="Get the downloadable 4-week Digital Sabbath Curriculum"
                disclaimer="We'll use this email to send you a 4 week curriculum to help you run a Sabbath with a group."
            />
            </div>
            <div className="mb-5 w-full mt-10 ">
                <GroupPlan />
            </div>
            <div className="mb-10">
            <GroupSignUp
                title=""
                disclaimer="We'll use this email to send you a 4 week curriculum to help you run a Sabbath with a group."
            />
            </div>
        </Layout>
    )
}


export default Groups;