import React from "react"


export const TabsContext = React.createContext({ tab: 0, setTab: () => { } });
const useTabsContext = ()=>React.useContext(TabsContext);


export function TabList({ children }) {
    return (
        <ul role="menu" className="flex w-full border-botton">
            {children}
        </ul>
    )
}

export function TabItem({  value, label }) {
    const { tab, setTab } = useTabsContext();
    return (
        <li
            role="menuitem"
            className={`flex-direction:row mx-2 p-3 w-1/4 cursor-pointer text-xl font-bold ${value === tab ? "text-primary border-b-2 border-solid border-primary font-bold":""}`}
            tabIndex={-1}
            onClick={() => { setTab(value);}}>
            {label}
        </li>
    );
}
export function TabPanel({ children, value }) {
    const { tab } = useTabsContext();
    return (
        <div
            className={`h-600 overflow-hidden mb-8 ${value !== tab ? "hidden":""}`}>
            {children}
        </div>
    );
}

export function TabContent({ children }) {
    return (
        <div
            class="p-4 "
        >
            {children}
        </div>
    )
}


export function Tabs({ labels, children }) {
    const [activeTab, setActiveTab] = React.useState(0);
    return (
        <TabsContext.Provider value={{ tab: activeTab, setTab: setActiveTab }}>
            <div>
            {children}</div>
        </TabsContext.Provider>
    )
}


