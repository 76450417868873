import React from "react";

export const DayPlan = ({ title, content, questions, prayers, practice, dayId }) => {
    return (
        <div className="p-8">
            <h3 className="text-2xl mb-2 text-primary font-bold">{title}</h3>
            <section  className="my-2 mx-6">
                    {content.split('\n').map(p=><p className="my-5">{p}</p>)}
            </section>
            <section className="my-2">
                <h4 className="mb-2 mt-4 text-primary font-bold text-xl">Discussion Questions</h4>
                <ol className="mx-6">
                    {questions.map((question, index) => (
                        <li className="my-4" key={`question_${dayId}_${index}`}>
                            {question}
                        </li>
                    ))}
                </ol>
            </section>

            <section className="my-2">
                <h4 className="my-2 text-primary font-bold text-xl">Prayer Ideas</h4>
                <div className="mx-6">
                    {prayers.map((item, index) => (<div className="mb-2     " key={`prayer_${dayId}_${index}`}>{item}</div>))}
                </div>
            </section>

        </div>

    )
}