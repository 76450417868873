import React from "react"
//import { StaticImage } from "gatsby-image"
import { validateEmail } from "../../utils/validators";
import SignUpDone from "../../../static/images/signup_done.svg";
import { appConfig, DAYS, SignUpState } from "../../utils/environment";

import FaithTech from "../../../static/images/faithtech_logo.svg"
import doc from "../../../static/docs/Downloadable_Digital_Sabbath_Small_Group_Curriculum.pdf"


export const GroupSignUp = (props) => {

    const [email, setEmail] = React.useState("");
    const [validEmail, setValidEmail] = React.useState(true);
    const [signUpComplete, setSignUpComplete] = React.useState(SignUpState.INCOMPLETE);
    const [processingRequest, setProcessingRequest] = React.useState(false);


    const handleChange = ({ target: { name, value } }) => {
        setSignUpComplete(SignUpState.INCOMPLETE);
        setValidEmail(false);
        if (name === "emailAddress") {
            //validate first
            if (!validateEmail(value)) {
                setValidEmail(false)
            }
            setEmail(email)
        }
    }

    const handleSignUp = React.useCallback(() => {
        // if (!email) {
        //     setSignUpComplete(SignUpState.ERROR);
        //     setValidEmail(false);
        //     setProcessingRequest(false);
        // }
        // if (email === '') {
        //     setSignUpComplete(SignUpState.ERROR);
        //     setProcessingRequest(false);
        //     return;
        // }
        // setProcessingRequest(true);

        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     referrerPolicy: 'no-referrer',
        //     body: JSON.stringify({ email })
        // };
        // fetch(appConfig.signUpUrl, requestOptions)
        //     .then(data => {
        //         if (data.ok) {
        //             setProcessingRequest(false);
        //             setSignUpComplete(SignUpState.COMPLETE);
        //         } else {
        //             throw new Error(data);
        //         }
        //     }).catch(error => {
        //         setProcessingRequest(false);
        //         setSignUpComplete(SignUpState.ERROR);
        //     });
    }, [email])

    return (
        <div className="container mt-28 flex flex-col items-center">
       
                <h1 className="mb-6">{props.title} </h1>     
                <div className="w-full  flex flex-col items-center">
                {signUpComplete === SignUpState.INCOMPLETE || signUpComplete === SignUpState.ERROR ? (
                    <>
                        {/* <label htmlFor="emailAddress" className="mb-2">
                            Email Address
                        </label>
                        <input
                            id="emailAddress"
                            name="emailAddress"
                            type="email"
                            className={`input-field w-full md:w-7/12 mb-2 ${!validEmail ? 'input-error ' : ''}`}
                            value={email}
                            onBlur={handleChange}
                            onChange={handleChange}
                        />
                        {!validEmail && <p className="px-2 mb-2 text-sm">* Valid email required</p>}

                        <p className="text-sm w-full md:w-2/3 mb-4">
                            {props.disclaimer}
                        </p> */}
                        <div className="mb-4">
                            <a
                                // onClick={handleSignUp}
                                href={doc}
                                target="_blank"
                                className="w-full md:w-50 btn-primary"
                                disabled={processingRequest ? 'disabled' : ''}
                            >
                                Get the curriculum
                            </a>
                            {signUpComplete === SignUpState.ERROR && (
                                <p className="text-red-700 w-full md:w-2/3 mb-4">
                                    There was a problem signing you up, please try again later.
                                </p>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <img
                            src={SignUpDone}
                            alt="Two people jumping next to a sign that says 'You\'re In'"
                            className="w-full md:w-80 block"
                        />
                    </>
                )}
            </div>
        </div>
    )
}


