import React from "react";
import { TabContent, TabList, Tabs, TabPanel, TabItem } from "../tabs";
import { DayPlan } from "./day_plan";
import * as plans from '../../../static/data/group_plan.json';


const loadDayPlans = ()=>{
    return plans.data;
}


export const GroupPlan = () => {
    const plans= loadDayPlans();

    return (
        <div className="mb-20 flex items-center w-full">
            <div className="w-full flex flex-col">
            <Tabs>
                <TabList>
                    <TabItem label="Day 1" value={0}/>
                    <TabItem label="Day 2" value={1}/>
                    <TabItem label="Day 3" value={2}/>
                    <TabItem label="Day 4" value={3}/>
                </TabList>
                <TabContent>
                    <TabPanel value={0}>
                        <DayPlan 
                            dayId={0}{...plans[0]}
                        />
                    </TabPanel>
                    <TabPanel value={1}>
                        <DayPlan 
                            dayId={1}{...plans[1]}
                        /></TabPanel>
                    <TabPanel value={2}>
                        <DayPlan 
                            dayId={2}{...plans[2]}
                        />
                        </TabPanel>
                    <TabPanel value={3}>
                        
                    <DayPlan 
                            dayId={3}{...plans[3]}
                        />
                    </TabPanel>
                </TabContent>
            </Tabs>
            </div>
        </div>
    )
}


